<template>
  <div
    ref="dropdownRef"
    class="relative"
    :class="[{ 'z-50': isDropdownShown }, { 'inline-flex': isMinimalistic }]"
  >
    <button v-if="isButtonLike" @click="isDropdownShown = !isDropdownShown">
      <SvgIcon name="dots-three-vertical" />
    </button>
    <div
      v-else
      class="dropdown-select__selected flex max-w-[230px] cursor-pointer items-center justify-between text-[18px] text-[#4d4d4d] 2xl:max-w-[initial]"
      :class="{ 'bg-white-100': !isMinimalistic }"
      @click="isDropdownShown = !isDropdownShown"
    >
      <span class="truncate">{{ getCurrentSelected }}</span>
      <SvgIcon
        :name="isDropdownShown ? 'chevron-up' : 'chevron-down'"
        class="ml-2 min-w-[16px]"
      />
    </div>
    <div
      v-if="isDropdownShown"
      class="custom-scrollbar absolute top-full flex max-h-[390px] w-auto flex-col overflow-scroll bg-white-100 py-2 shadow-drop-2"
      :class="isMinimalistic || isButtonLike ? 'w-fit' : 'w-full'"
    >
      <div
        v-for="(item, index) in list"
        :key="`select-item-${item.id}-${index}`"
        class="cursor-pointer px-2 py-1 hover:bg-new-gray-lighter"
        @click="handleSelect(item.id)"
      >
        {{ item && item.label ? item.label : '' }}
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: undefined,
  },

  list: {
    type: Array,
    default: () => [],
  },

  // initialSelectValue: {
  //   type: Number,
  //   default: 0,
  // },

  isMinimalistic: {
    type: Boolean,
    default: false,
  },

  isButtonLike: {
    type: Boolean,
    default: false,
  },

  isOnChangeLocalVarsResetEnabled: {
    type: Boolean,
    default: false,
  },

  placeholder: {
    type: String,
    default: 'Выберите...',
  },
});

const emit = defineEmits(['on-select', 'update:modelValue']);

const currentSelectedId = ref(undefined);
const isDropdownShown = ref(false);
const dropdownRef = ref(null);

const getCurrentSelected = computed(() => {
  let res = '';
  if (typeof currentSelectedId.value === 'undefined' && props.modelValue) {
    res = props.list.find((el) => el.id === props.modelValue)?.label;
  } else if (currentSelectedId.value) {
    res = props.list.find((el) => el.id === currentSelectedId.value)?.label;
  } else {
    res = props.placeholder;
  }

  const truncLength = 31;
  if (res.length > truncLength) {
    res = `${res.slice(0, truncLength)}...`;
  }

  return res;
});

const handleSelect = (id) => {
  currentSelectedId.value = id;
  isDropdownShown.value = false;
  emit('update:modelValue', currentSelectedId.value);

  if (props?.isOnChangeLocalVarsResetEnabled) {
    currentSelectedId.value = undefined;
  }
};

const closeDropdown = () => {
  isDropdownShown.value = false;
};

onClickOutside(dropdownRef, () => {
  closeDropdown();
});
</script>

<style lang="scss"></style>
