<template>
  <div
    class="transition-effect mb-7 flex cursor-pointer items-center rounded-lg bg-ruby-red-100 text-white-100 hover:opacity-90"
  >
    <div
      class="grid h-[64px] w-[64px] place-items-center rounded-lg bg-black-10"
    >
      <SvgIcon name="mail" class="icons-big" />
    </div>
    <div class="ml-[-64px] flex grow justify-center">E-mail</div>
  </div>
</template>

<script setup></script>

<style lang="scss"></style>
