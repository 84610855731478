<template>
  <ModalsAuthorizationAuthModalWrapper>
    <div class="flex flex-col lg:min-w-[736px]">
      <h6 class="mb-16 font-roboto text-[31px] font-medium leading-[38px]">
        Регистрация на портале
        <span class="text-ruby-red-100">“История.рф”</span>
      </h6>
      <form>
        <BaseTheInput
          v-model="formData.email"
          placeholder="Введите ваш e-mail"
          is-redesign-auth
          type="email"
          class="mb-4"
          :error="v$.email.$errors[0]?.$message"
        />
        <BaseTheInput
          v-model="formData.name"
          placeholder="Введите ваше имя"
          is-redesign-auth
          class="mb-4"
          :error="v$.name.$errors[0]?.$message"
        />
        <BaseTheInput
          v-model="formData.password"
          placeholder="Введите ваш пароль"
          type="password"
          is-redesign-auth
          :error="v$.password.$errors[0]?.$message"
        />
      </form>
      <BaseTheButtonNew
        text="Зарегистрироваться"
        type="secondary"
        class="mt-12 max-h-[64px] w-full justify-center sm:w-full"
        @click="proceedRegistration"
      />
    </div>
  </ModalsAuthorizationAuthModalWrapper>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, minLength, required } from '@vuelidate/validators';

import { validationErrors } from '@/assets/data/validateErrors';

const authStore = useAuthStore();
const toast = useToast();

const formData = ref({
  email: null,
  password: null,
  name: null,
});

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(validationErrors.required, required),
      email: helpers.withMessage(validationErrors.email, email),
    },
    password: {
      required: helpers.withMessage(validationErrors.required, required),
      minLength: helpers.withMessage(
        validationErrors.passwordMinLength,
        minLength(8),
      ),
    },
    name: {
      required: helpers.withMessage(validationErrors.required, required),
    },
  };
});

const v$ = useVuelidate(rules, formData);

const proceedRegistration = () => {
  v$.value.$touch();

  if (v$.value.$invalid) {
    return 0;
  }

  const user = {
    email: formData.email,
    name: formData.name,
    password: formData.password,
    password_confirmation: formData.password,
  };

  try {
    authStore.register(user);
  } catch (e) {
    toast.error(e);
  }
};

/*
import { mapGetters } from 'vuex';

import TheButtonNew from '@/components/Base/TheButtonNew';
import TheInput from '@/components/Base/TheInput';
import AuthModalWrapper from '@/components/Modals/Authorization/AuthModalWrapper';

export default {
  components: {
    AuthModalWrapper,
    TheButtonNew,
    TheInput,
  },

  validations: {
    email: { required, email },
    password: { required, minLength: minLength(8) },
    name: { required },
  },

  data() {
    return {
      email: null,
      name: null,
      password: null,
    };
  },

  computed: {
    ...mapGetters({
      validationErrorList: 'errors/getValidationErrorList',
    }),

    emailErrors() {
      const errors = [];
      if (this.$v.email.$dirty && !this.$v.email.required)
        errors.push(this.validationErrorList.required);
      if (this.$v.email.$dirty && !this.$v.email.email)
        errors.push(this.validationErrorList.email);
      return errors;
    },

    nameErrors() {
      const errors = [];
      if (this.$v.name.$dirty && !this.$v.name.required)
        errors.push(this.validationErrorList.required);
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (this.$v.password.$dirty && !this.$v.password.required)
        errors.push(this.validationErrorList.required);
      if (this.$v.password.$dirty && !this.$v.password.minLength)
        errors.push(this.validationErrorList.passwordMinLength);
      return errors;
    },
  },

  methods: {
    async proceedRegistration() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return 0;
      }

      const user = {
        email: this.email,
        name: this.name,
        password: this.password,
        password_confirmation: this.password,
      };

      try {
        await this.$store.dispatch('auth/register', user);
      } catch (e) {
        this.$showNotification('error', e);
      }
    },
  },
};*/
</script>

<style lang="scss"></style>
