<template>
  <div>
    <span
      v-if="label"
      class="the-input-label text-black-56"
      :class="
        isRedesignLabel
          ? 'text-[18px] leading-[18px] xl:text-[20px] xl:leading-[24px] mb-6 text-new-test-black font-normal font-roboto block'
          : 'mb-1 text-smaller'
      "
      >{{ label }}</span
    >
    <div class="relative flex h-fit items-center">
      <textarea
        :value="modelValue"
        :placeholder="placeholder"
        class="text-small custom-scrollbar h-full min-h-24 w-full rounded-lg border border-new-card--border p-4 font-roboto text-[18px] leading-[22px] placeholder-shown:text-[16px] placeholder-shown:leading-[19px]"
        @input="emit('update:modelValue', $event.target.value)"
      />
    </div>
    <span v-if="error" class="text-smaller mt-1 text-ruby-red-100">
      {{ error }}
    </span>
  </div>
</template>

<script setup>
defineProps({
  modelValue: {
    type: [Number, String],
    default: undefined,
  },

  placeholder: {
    type: String,
    default: '',
  },

  isRedesignLabel: {
    type: Boolean,
    default: false,
  },

  label: {
    type: String,
    default: '',
  },

  error: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue', 'onClick']);
</script>
