<template>
  <ModalsTheModal :is-full="!formSendSuccefull">
    <div class="rounded-md bg-white-100 p-4 pt-10 lg:p-14">
      <SvgIcon
        name="close"
        class="icons-medium absolute right-3 top-3 cursor-pointer md:text-[#949494]"
        @click="() => modalsStore.closeModal()"
      />
      <div
        v-if="formSendSuccefull"
        class="flex w-fit flex-col items-center md:flex-row"
      >
        <img
          v-lazy-load
          src="/images/temp-1.png"
          alt=""
          class="max-w-[242px]"
        />
        <div class="flex flex-col p-6">
          <span class="mb-2 text-[31px] font-medium leading-[38px]"
            >Вопрос отправлен</span
          >
          <span class="text-[14px] leading-[21px] text-[#4D4D4D]"
            >Ожидайте ответа на указанную почту
          </span>
        </div>
      </div>

      <div v-else class="relative flex w-full flex-col">
        <div
          class="mb-8 flex flex-col items-center bg-collection p-2 md:flex-row"
        >
          <img
            v-lazy-load
            src="/images/temp.png"
            alt=""
            class="max-w-[134px]"
          />
          <div class="flex flex-col text-white-100 md:ml-6">
            <span class="mb-2 text-[31px] font-medium leading-[38px]"
              >Спросить историка</span
            >
            <span class="text-[14px] leading-[21px]"
              >Задайте вопрос и получите экспертный ответ на почту
            </span>
          </div>
        </div>
        <div class="mb-4">
          <BaseTheInput
            v-model="name"
            label="Как к Вам обращаться?"
            placeholder="Ваше имя"
            is-redesign-auth
            is-redesign-label
            :errors="nameErrors"
          />
        </div>
        <div class="mb-4">
          <BaseTheInput
            v-model="email"
            label="Укажите почту, на которую ожидаете ответ"
            placeholder="Введите e-mail"
            is-redesign-auth
            is-redesign-label
            :errors="emailErrors"
          />
        </div>
        <div class="mb-4">
          <span
            class="the-input-label mb-6 block font-roboto text-[18px] font-normal leading-[18px] text-new-test-black xl:text-[20px] xl:leading-[24px]"
            >Выберите тему вопроса</span
          >
          <BaseTheDropdownSelectNew
            v-model="theme"
            :list="themesList"
            placeholder="Выберите тему"
            :errors="themeErrors"
          />
        </div>
        <div class="mb-8">
          <BaseTheTextArea
            v-model="message"
            label="Напишите вопрос"
            placeholder="Текст вопроса. При вопросе к статье укажите название."
            is-redesign-auth
            is-redesign-label
            :errors="messageErrors"
          />
        </div>

        <div
          class="mb-8 flex flex-wrap items-center text-[14px] leading-[24px]"
        >
          <label class="mr-1.5 flex cursor-pointer items-center">
            <BaseTheCheckbox
              id="politic-confirmation"
              v-model="isConfirm"
              red-checkbox
              label=""
            />
            <span>Я даю согласие на</span></label
          ><a
            class="text-collection"
            target="_blank"
            href="https://histrf.ru/uploads/media/default/0001/47/5491158e052d1a6419969f91e6effa7fafd60ade.pdf"
            >обработку персональных данных</a
          >
        </div>

        <BaseTheButtonNew
          text="Отправить"
          type="secondary"
          class="justify-center sm:w-full"
          :disabled="!isConfirm"
          @click="handleSendQuestion"
        />
      </div>
    </div>
  </ModalsTheModal>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, minLength, required } from '@vuelidate/validators';

import { validationErrors } from '@/assets/data/validateErrors';
import api from '@/services/api/api';

const authStore = useAuthStore();
const modalsStore = useModalsStore();
const toast = useToast();

const themesList = [
  { id: 1, label: 'Вопрос к статье' },
  { id: 2, label: 'Об историческом событии' },
  { id: 3, label: 'Об исторической личности' },
  { id: 4, label: 'Другое' },
];

const formData = ref({
  name: null,
  email: null,
  theme: null,
  message: null,
});
const isConfirm = ref(false);
const formSendSuccefull = ref(false);

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(validationErrors.required, required),
      email: helpers.withMessage(validationErrors.email, email),
    },
    name: {
      required: helpers.withMessage(validationErrors.required, required),
    },
    theme: {
      required: helpers.withMessage(validationErrors.required, required),
    },
    message: {
      required: helpers.withMessage(validationErrors.required, required),
      minLength: helpers.withMessage(
        validationErrors.messageMinLength,
        minLength(8),
      ),
    },
  };
});

const v$ = useVuelidate(rules, formData);

const getUserData = computed(() => {
  return authStore.getUserInfo;
});

onMounted(() => {
  formData.value.name = getUserData.value.name;
  formData.value.email = getUserData.value.email;
});

const handleSendQuestion = async () => {
  if (!isConfirm.value) return;

  v$.value.$touch();

  if (v$.value.$invalid) {
    return 0;
  }
  const body = {
    name: formData.value.name,
    email: formData.value.email,
    theme: themesList.find(({ id }) => id === formData.value.theme)?.label,
    message: formData.value.message,
  };

  try {
    await api.others.sendQuestion(body);
    formSendSuccefull.value = true;
  } catch (e) {
    toast.error('Ошибка!');
    console.log(e);
  }
};
</script>

<style lang="scss">
.the-input {
  @apply h-[54px];
  input {
    padding: 16px !important;
  }
}
.the-input-label {
  @apply mb-0.5 text-[18px] leading-7;
}
.dropdown-select__selected {
  @apply p-4 w-full rounded-lg leading-[22px] text-[18px] font-roboto max-w-[initial] border border-new-card--border;
}
</style>
