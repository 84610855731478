<template>
  <div
    v-if="isLessThanHorizontalPad && (isIos || isAndroid)"
    class="fixed inset-x-0 top-0 z-100 flex h-16 max-h-16 items-center justify-between bg-white-100 p-2 shadow-drop-0"
  >
    <div class="flex h-full w-2/3 gap-2">
      <div class="flex w-3/12 items-center">
        <img
          v-lazy-load
          src="/images/banners/mobile-application-avatar.webp"
          alt=""
          class="h-full w-full rounded-lg object-cover"
        />
      </div>
      <div class="flex w-9/12 flex-col justify-evenly">
        <p class="mb-2 text-xs font-semibold">Открыть в приложении</p>
        <div class="flex gap-1">
          <SvgIcon
            v-for="i in 5"
            :key="`mobile-application-rating-${i}`"
            name="star"
          />
        </div>
      </div>
    </div>
    <div class="w-1/3">
      <BaseTheButton
        is-small
        is-external
        text="Открыть"
        type="secondary"
        :href="getStoreUrl"
      />
    </div>
  </div>
</template>

<script setup>
const { isLessThanHorizontalPad } = useBreakpoint();

const props = defineProps({
  isAndroid: {
    type: Boolean,
    default: false,
  },

  isIos: {
    type: Boolean,
    default: false,
  },
});

const getOsName = computed(() => {
  if (props.isAndroid) {
    return 'Android';
  } else if (props.isIos) {
    return 'iOS';
  } else {
    return null;
  }
});

const getStoreUrl = computed(() => {
  let res = '';
  switch (getOsName.value) {
    case 'iOS':
      res = 'itms-apps://itunes.apple.com/app/id1590370676';
      break;
    case 'Android':
    default:
      res = 'https://play.google.com/store/apps/details?id=ru.histrf.mobile';
      break;
  }
  return res;
});
</script>

<style lang="scss"></style>
