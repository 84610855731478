<template>
  <ModalsAuthorizationAuthModalWrapper>
    <div class="mt-8 flex flex-col lg:min-w-[736px]">
      <BaseTheInput
        v-model="emailRef"
        placeholder="Введите ваш e-mail"
        is-redesign-auth
        type="email"
        :error="v$.email.$errors[0]?.$message"
      />
      <BaseTheButtonNew
        text="Восстановить пароль"
        type="secondary"
        class="mb-5 mt-12 max-h-[64px] w-full justify-center sm:w-full"
        @click="resetPassword"
      />
      <BaseTheButtonNew
        text="Вернуться к авторизации"
        class="mb-8 max-h-[64px] w-full justify-center sm:w-full"
        @click="modalsStore.showEmailAuthForm()"
      />
    </div>
  </ModalsAuthorizationAuthModalWrapper>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';

import { validationErrors } from '@/assets/data/validateErrors';

const authStore = useAuthStore();
const modalsStore = useModalsStore();
const toast = useToast();
const emailRef = ref(null);

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(validationErrors.required, required),
      email: helpers.withMessage(validationErrors.email, email),
    },
  };
});

const v$ = useVuelidate(rules, { email: emailRef });
const resetPassword = () => {
  v$.value.$touch();

  if (v$.value.$invalid) {
    return 0;
  }

  try {
    authStore.forgotPassword({
      email: emailRef.value,
    });
  } catch (e) {
    toast.error(e);
  }
};
/*
import { mapGetters } from 'vuex';

import TheButtonNew from '@/components/Base/TheButtonNew';
import TheInput from '@/components/Base/TheInput';
import AuthModalWrapper from '@/components/Modals/Authorization/AuthModalWrapper';

export default {
  components: {
    AuthModalWrapper,
    TheButtonNew,
    TheInput,
  },

  validations: {
    email: { required, email },
  },

  data() {
    return {
      email: null,
    };
  },

  computed: {
    ...mapGetters({
      validationErrorList: 'errors/getValidationErrorList',
    }),

    emailErrors() {
      const errors = [];
      if (this.$v.email.$dirty && !this.$v.email.required)
        errors.push(this.validationErrorList.required);
      if (this.$v.email.$dirty && !this.$v.email.email)
        errors.push(this.validationErrorList.email);
      return errors;
    },
  },

  methods: {
    async resetPassword() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return 0;
      }

      try {
        await this.$store.dispatch('auth/forgotPassword', {
          email: this.email,
        });
      } catch (e) {
        this.$showNotification('error', e);
      }
    },
  },
};*/
</script>

<style lang="scss"></style>
