<template>
  <ModalsAuthorizationAuthModalWrapper>
    <div class="mt-9 flex flex-col lg:min-w-[736px]">
      <BaseTheInput
        v-model="password"
        placeholder="Введите ваш новый пароль"
        is-redesign-auth
        type="email"
        :errors="v$.password.$errors[0]?.$message"
      />
      <BaseTheButtonNew
        text="Сменить пароль"
        type="secondary"
        class="mt-12 max-h-[64px] w-full justify-center sm:w-full"
        @click="setNewPassword"
      />
    </div>
  </ModalsAuthorizationAuthModalWrapper>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';

import { validationErrors } from '@/assets/data/validateErrors';

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const toast = useToast();
const password = ref(null);

const rules = computed(() => {
  return {
    password: {
      required: helpers.withMessage(validationErrors.required, required),
      minLength: helpers.withMessage(
        validationErrors.passwordMinLength,
        minLength(8),
      ),
    },
  };
});

const v$ = useVuelidate(rules, { password });

const setNewPassword = async () => {
  v$.value.$touch();

  if (v$.value.$invalid) {
    return 0;
  }

  const data = {
    token: route.query.token,
    email: route.query.email,
    password: password.value,
    password_confirmation: password.value,
  };

  try {
    await authStore.resetPassword(data);
    await router.push('/');
  } catch (e) {
    toast.error(e);
  }
};

/*
import { mapGetters } from 'vuex';

import TheButtonNew from '@/components/Base/TheButtonNew';
import TheInput from '@/components/Base/TheInput';
import AuthModalWrapper from '@/components/Modals/Authorization/AuthModalWrapper';

export default {
  components: {
    AuthModalWrapper,
    TheButtonNew,
    TheInput,
  },

  validations: {
    password: { required, minLength: minLength(8) },
  },

  data() {
    return {
      password: null,
    };
  },

  computed: {
    ...mapGetters({
      validationErrorList: 'errors/getValidationErrorList',
    }),

    passwordErrors() {
      const errors = [];
      if (this.$v.password.$dirty && !this.$v.password.required)
        errors.push(this.validationErrorList.required);
      if (this.$v.password.$dirty && !this.$v.password.minLength)
        errors.push(this.validationErrorList.passwordMinLength);
      return errors;
    },
  },

  methods: {
    async setNewPassword() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return 0;
      }

      const data = {
        token: this.$route.query.token,
        email: this.$route.query.email,
        password: this.password,
        password_confirmation: this.password,
      };

      try {
        await this.$store.dispatch('auth/resetPassword', data);
        await this.$router.push('/');
      } catch (e) {
        this.$showNotification('error', e);
      }
    },
  },
};*/
</script>

<style lang="scss"></style>
