<template>
  <footer
    class="mt-auto py-8 lg:py-14"
    :class="landing ? 'bg-[#313131]' : 'bg-new-black'"
  >
    <div class="grid-layout container">
      <a
        href="https://rvio.histrf.ru/"
        target="_blank"
        class="col-span-full flex items-center justify-center lg:col-span-3 xl:col-span-2"
      >
        <SvgIcon name="logo-footer-light" style="width: 224px; height: 130px" />
      </a>
      <div
        class="col-span-full grid grid-cols-9 lg:col-span-9 lg:col-start-4 lg:ml-12 xl:ml-0"
      >
        <FooterSubscriptionForm
          class="col-span-full row-start-1 row-end-1 my-14 flex items-center lg:mb-8 lg:mt-0 xl:col-span-5"
        />
        <div
          class="col-span-full row-start-3 row-end-3 my-14 flex items-center md:col-span-5 lg:col-span-3 lg:mb-0 lg:mt-10 xl:col-span-3 xl:col-start-7 xl:row-start-1 xl:row-end-1 xl:mb-8 xl:mt-0"
          :class="
            landing
              ? '2xl:justify-end'
              : 'justify-end md:justify-start lg:justify-end'
          "
        >
          <CommonSocials />
        </div>
        <div
          class="text-smaller col-span-full row-start-2 row-end-2 text-white-50 lg:mt-auto xl:col-span-5"
        >
          © 2012–{{ fullYear }}, Российское военно-историческое общество. Все
          права защищены. При цитировании и копировании материалов с портала
          «История.РФ» активная гиперссылка обязательна
        </div>
        <div
          class="text-smaller col-span-full row-start-4 row-end-4 flex items-center text-white-50 md:col-span-4 md:row-start-3 md:row-end-3 lg:col-span-5 lg:col-start-5 lg:items-end lg:text-right xl:col-span-4 xl:row-start-2 xl:row-end-2 xl:items-center xl:justify-end"
        >
          <div class="mr-4 flex flex-col 2xl:pl-4">
            <a
              href="https://histrf.ru/uploads/media/default/0001/47/5491158e052d1a6419969f91e6effa7fafd60ade.pdf"
              target="_blank"
              class="mb-2"
            >
              Правила обработки и защиты персональных данных
            </a>
            <span class="mb-2">
              *В оформлении использованы фотографии из источников
              <a
                href="https://warheroes.ru/"
                target="_blank"
                rel="noopener"
                class="underline"
                >warheroes.ru</a
              >
              и
              <a
                href="https://waralbum.ru/"
                target="_blank"
                rel="noopener"
                class="underline"
                >waralbum.ru</a
              >
            </span>
            <NuxtLink v-if="!landing" to="/about">О проекте</NuxtLink>
          </div>
          <ClientOnly v-if="!landing">
            <!-- Yandex.Metrika informer -->
            <a
              href="https://metrika.yandex.ru/stat/?id=20561137&amp;from=informer"
              target="_blank"
              rel="nofollow"
              class="min-h-[46.5px] min-w-[132px]"
              ><img
                src="https://informer.yandex.ru/informer/20561137/3_0_999999FF_999999FF_1_pageviews"
                style="width: 132px; height: 46.5px; border: 0"
                alt="Яндекс.Метрика"
                title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)"
                class="ym-advanced-informer"
                data-cid="20561137"
            /></a>
            <!-- /Yandex.Metrika informer -->
          </ClientOnly>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
defineProps({
  landing: {
    type: Boolean,
    default: false,
  },
});

const fullYear = computed(() => {
  return new Date().getFullYear();
});
</script>

<style lang="scss" scoped>
.nuxt-link-active {
  @apply text-white-50;
}
</style>
