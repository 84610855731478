<template>
  <div class="relative flex items-center">
    <NuxtLink to="/profile" class="relative flex items-center">
      <CommonUserAvatar
        form="square"
        class="cursor-pointer"
        :path="
          getUserInfo.image && getUserInfo.image.preview
            ? getUserInfo.image.preview
            : ''
        "
      />
      <CommonCounter v-if="false" />
    </NuxtLink>
  </div>
</template>

<script setup>
const authStore = useAuthStore();

const getUserInfo = computed(() => {
  return authStore.getUserInfo;
});
</script>

<style lang="scss"></style>
